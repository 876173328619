import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import TenantInfo from '../../../components/TenantIdInfo';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useLoader } from '../../../stores/use-loader';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { ICustomerLevelInfo } from '../types/earn-rules';
import { IRewardListItem } from '../types/rewards';
import { PlusOutlined } from '@ant-design/icons';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

interface ICustomerTierProps {}

const CustomerTier: React.FunctionComponent<ICustomerTierProps> = props => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [customerLevelsData, setCustomerLevelsData] = React.useState({} as ICustomerLevelInfo);

  const [rewardTypeMap, setRewardTypeMap] = React.useState<Record<string, IRewardListItem[]>>({});

  React.useEffect(() => {
    fetchCustomerLevelInfo();
    form.setFieldsValue({
      default_name: 'Standard',
      default_level_threshold: 0
    });
  }, []);

  React.useEffect(() => {
    fetchRewardNames();
  }, []);

  const fetchRewardNames = async () => {
    setLoading(true);
    await Promise.allSettled([fetchRewardNamesByPoints(), fetchRewardNameByCash()]);
    setLoading(false);
  };

  const fetchRewardNamesByPoints = async () => {
    const { data, errors } = await loyaltyService.getRewardsByType('POINTS');
    setRewardTypeMap(map => ({ ...map, POINTS: data }));
  };

  const fetchRewardNameByCash = async () => {
    const { data, errors } = await loyaltyService.getRewardsByType('CASH');
    setRewardTypeMap(map => ({ ...map, CASH: data }));
  };

  const fetchCustomerLevelInfo = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getCustomerLevel();

    if (_.isEmpty(errors)) {
      setCustomerLevelsData(data);
      form.setFieldsValue(data);
      form.setFieldValue('total_level', data?.levels?.length);
    }

    setLoading(false);
  };

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const handleFormSubmit = async () => {
    setLoading(true);
    const { level_type, interval_count, interval, total_level, levels, reward_type, reward_name, default_name } =
      form.getFieldsValue();

    const request = {
      level_type,
      total_level,
      registration_level: default_name,
      interval,
      interval_count,
      levels,
      reward_type,
      reward_name
    };

    const updatedLevels = levels.map((level: any, index: number) => {
      return { ...level, hierarchy_level: index + 1 };
    });

    request.levels = updatedLevels;

    const { errors } = await loyaltyService.createCustomerLevel(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t(isCreated ? 'customerLevelsUpdated' : 'customerLevelsCreated') });
      fetchCustomerLevelInfo();
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const isCreated = !_.isEmpty(customerLevelsData);

  const labelText = isCreated ? t('update') : t('create');

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('customerLevels')}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />

          <Form form={form} className="mt-4" onFinish={handleFormSubmit} layout="vertical">
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                marginBottom: '1rem',
                borderRadius: '10px'
              }}
            >
              <div>
                <Typography.Title level={5}>{t('generalDetails')} </Typography.Title>
              </div>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="level_type"
                    label={t('levelType')}
                    rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                  >
                    <Select
                      size="large"
                      placeholder={t('selectLevelType')}
                      disabled={customerLevelsData?.levels?.length > 0}
                      options={[
                        { label: t('reward'), value: 'REWARD' },
                        { label: t('purchase'), value: 'PURCHASE' }
                      ]}
                    />
                  </Form.Item>
                </Col>{' '}
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.level_type !== curValues.level_type;
                  }}
                >
                  {form => {
                    const { level_type } = form.getFieldsValue();
                    return (
                      level_type === 'REWARD' && (
                        <>
                          <Col xs={12} md={6}>
                            <Form.Item
                              name="reward_type"
                              label={t('rewardType')}
                              rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                            >
                              <Select
                                size="large"
                                placeholder={t('selectRewardType')}
                                disabled={customerLevelsData?.levels?.length > 0}
                                options={[
                                  { label: t('points'), value: 'POINTS' },
                                  { label: t('cash'), value: 'CASH' }
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) => {
                                return prevValues.reward_type !== curValues.reward_type;
                              }}
                            >
                              {form => {
                                const { reward_type } = form.getFieldsValue();
                                const rewardList = rewardTypeMap[reward_type] || [];
                                const options = rewardList.map(({ name }) => ({ label: name, value: name }));
                                return (
                                  <Form.Item
                                    name="reward_name"
                                    label={t('rewardName')}
                                    rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                                  >
                                    <Select
                                      size="large"
                                      placeholder={t('selectRewardName')}
                                      disabled={customerLevelsData?.levels?.length > 0}
                                      options={options || []}
                                    />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>
                        </>
                      )
                    );
                  }}
                </Form.Item>
              </Row>
            </div>
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                marginBottom: '1rem',
                borderRadius: '10px'
              }}
            >
              <Typography.Title level={3}>{t('configureHierarchy')} </Typography.Title>

              <div className="mt-6">
                <Typography.Title level={5}>{t('configureEvaluationSettings')}</Typography.Title>
              </div>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="interval"
                    label={t('interval')}
                    rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                  >
                    <Select
                      placeholder={t('selectInterval')}
                      disabled={customerLevelsData?.levels?.length > 0}
                      options={[
                        { label: t('quarterly'), value: 'QUATERLY' },
                        { label: t('monthly'), value: 'MONTHLY' },
                        { label: t('yearly'), value: 'YEARLY' }
                      ]}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="interval_count"
                    label={t('intervalCount')}
                    rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                  >
                    <InputNumber
                      size="large"
                      min={0}
                      className="w-full"
                      disabled={customerLevelsData?.levels?.length > 0}
                      placeholder={t('enterIntervalCount')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Card>
                <div>
                  <Typography.Title level={5}>{t('defaultLevelHierarchy')}</Typography.Title>
                </div>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="default_name"
                      label={t('name')}
                      rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                    >
                      <Input placeholder="" size="large" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'default_level_threshold'}
                      label={t('levelThreshold')}
                      rules={[{ required: true, message: t('levelThresholdIsRequired') }]}
                    >
                      <InputNumber min={0} className="w-full" size="large" placeholder="..."></InputNumber>
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  <Typography.Title level={5}>{t('configureAdditionalHierarchy')}</Typography.Title>
                </div>
                <Row gutter={12} className="mt-4">
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="total_level"
                      label={t('configureAdditionalHierarchyLevels')}
                      rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                    >
                      <InputNumber
                        max={5}
                        size="large"
                        min={0}
                        className="w-full"
                        placeholder={t('configureAdditionalHierarchyLevels')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} className="mt-8">
                    <Button
                      onClick={() => {
                        let value = form.getFieldsValue().total_level;
                        if (value) {
                          if (value > 5) return;
                          const levels = form.getFieldValue('levels') || [];
                          const hierarchyLevels = levels?.length || 0;
                          if (hierarchyLevels > value) {
                            levels.splice(-(hierarchyLevels - value));
                            form.setFieldsValue({ levels: [...levels] });
                          } else {
                            const levelsToBeAdded = (value || 0) - hierarchyLevels;

                            const fields = Array.from({ length: levelsToBeAdded || 0 }, (_, i) => ({
                              name: '',
                              level_threshold: ''
                            }));
                            form.setFieldsValue({ levels: [...levels, ...fields] });
                          }
                        }
                      }}
                      icon={<PlusOutlined />}
                    >
                      <BoldButtonLabel labelText={t('setHierarchy')} />
                    </Button>
                  </Col>
                </Row>

                <Form.List name="levels">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <React.Fragment key={index}>
                          <div>
                            <Typography.Title level={5}>
                              {t('levelHierarchy')} {index + 1}
                            </Typography.Title>
                          </div>
                          <Row key={index} gutter={12}>
                            <Col xs={12} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                label={t('name')}
                                rules={[{ required: true, message: t('nameIsRequired') }]}
                              >
                                <Input className="w-full" size="large" placeholder="..."></Input>
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, 'level_threshold']}
                                label={t('levelThreshold')}
                                rules={[{ required: true, message: t('levelThresholdIsRequired') }]}
                              >
                                <InputNumber min={0} className="w-full" size="large" placeholder="..."></InputNumber>
                              </Form.Item>
                            </Col>
                          </Row>
                        </React.Fragment>
                      ))}
                      <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                    </>
                  )}
                </Form.List>
              </Card>
            </div>

            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Button block type="primary" htmlType="submit">
                  <BoldButtonLabel labelText={labelText} />
                </Button>
              </Col>
              {isCreated && (
                <Col xs={12} md={6}>
                  <Button
                    block
                    type="default"
                    onClick={() => {
                      form.setFieldsValue(customerLevelsData);
                      form.setFieldValue('total_level', customerLevelsData.levels?.length || 0);
                    }}
                  >
                    <BoldButtonLabel labelText={t('reset')} />
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CustomerTier;
