import { Button, Card, Col, DatePicker, Form, Input, Row, Select, TimePicker, Typography } from "antd"
import PrimaryLayout from "../../../../layouts/primary-layout"
import TenantInfo from "../../../../components/TenantIdInfo"
import { createBurnRulesInitialXml, createEarnRulesInitialXml, createRuleTriggerTypeData, triggerTypeSelectOptions } from "../../data";
import React from "react";
import BoldButtonLabel from "../../../../components/BoldButtonLabel";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../../../stores/use-loader";
import _ from "../../../../helpers/lodash";
import DmnModeler from 'dmn-js';
import { loyaltyService } from "../../services/loyalty.service";
import { displayErrorNotifications } from "../../../../helpers/toast.helpers";
import { useTimezone } from "../../../../hooks/useTimezone";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';

interface IBurnRulesViewProps { }

const BurnRuleView: React.FunctionComponent<IBurnRulesViewProps> = props => {
    const { t } = useTranslation();
    const [createForm] = Form.useForm();
    const { id } = useParams();
    const navigate = useNavigate();
    const { timezoneSelectOptions } = useTimezone();

    const [burnRuleDetails, setBurnRuleDetails] = React.useState({} as any);
    const modellerRef = React.useRef<any>(null);

    const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

    React.useEffect(() => {
        if (_.isEmpty(burnRuleDetails)) {
            fetchRuleDetails();
        }

        if (modellerRef.current === null && burnRuleDetails.xml) {
            initializeModeller();
        }

        return () => {
            if (modellerRef.current !== null) {
                modellerRef.current.detach();
                modellerRef.current = null;
            }
        };
    }, [burnRuleDetails]);

    const fetchRuleDetails = async () => {
        setLoading(true);
        // const { data, errors } = await loyaltyService.getEarnRuleDetail('7baeafa1-9e10-4a45-95fa-af94137d4859' as string);
        const { data, errors } = await loyaltyService.getBurnRuleDetail(id as string);
        if (_.isEmpty(errors)) {
            setBurnRuleDetails(data);
            let formValues = {
                burn_template_type: data.burn_template_type,
                name: data.name,
                reward_name: data.reward_name,
                timezone: data.time_zone,
                date_range: [dayjs(data.start_date), dayjs(data.end_date)]
            }
            createForm.setFieldsValue(formValues);
        } else {
            displayErrorNotifications(errors);
        }
        setLoading(false);
    };

    const initializeModeller = async () => {
        const modeller = new DmnModeler({
            container: '#viewBurnRulesCanvas'
        });
        modellerRef.current = modeller;
        try {
            const { warnings } = await modeller.importXML(burnRuleDetails.xml);
            if (warnings.length) {
                console.log('import with warnings', warnings);
            } else {
                console.log('import successful');
            }
        } catch (err) {
            console.log('something went wrong:', err);
        }
        // const modeller = new DmnModeler({
        //     container: '#viewEarnRulesCanvas'
        //   });
        //   modellerRef.current = modeller;
        //   try {
        //     const { warnings } = await modeller.importXML(burnRuleDetails.xml);
      
        //     if (warnings.length) {
        //       console.log('import with warnings', warnings);
        //     } else {
        //       console.log('import successful');
        //     }
        //   } catch (err) {
        //     console.log('something went wrong:', err);
        //   }
    };

    return (
        <PrimaryLayout>
            <div className="container mx-auto px-4">
                <Card>
                    <Row justify={'space-between'} className="mb-4">
                        <Col>
                            <Typography.Title level={3} className="text-[#2e2a5b]">
                                {t('viewBurnRule')}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <div className="flex gap-4">
                        <TenantInfo />
                    </div>
                    <section className="mt-4">
                        <Form layout="vertical" form={createForm}>
                            <Row gutter={12}>
                                <Col xs={24} md={6}>
                                    <Form.Item name="burn_template_type" label={t('templateType')}>
                                        <Select
                                            placeholder={t('selectTemplateType')}
                                            size="large"
                                            options={[
                                                { label: t('customerOrder'), value: 'CUSTOMER_ORDER' },
                                                { label: t('giftCard'), value: 'GIFT_CARD' },
                                                { label: t('transferReward'), value: 'TRANSFER_REWARD' },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        rules={[
                                            { message: t('thisFieldIsRequired'), required: true },
                                            {
                                                validator: async (_, ruleName) => {
                                                    if (/\s/.test(ruleName)) {
                                                        return Promise.reject(new Error(t('noSpacesAllowed')));
                                                    }
                                                }
                                            }
                                        ]}
                                        name="name"
                                        label={t('ruleName')}
                                    >
                                        <Input placeholder={t('enterRuleName')} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                                        name="reward_name"
                                        label={t('rewardName')}
                                    >
                                        <Input placeholder={t('enterRewardName')} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                                        name="timezone"
                                        label={t('timezone')}
                                    >
                                        <Select options={timezoneSelectOptions} placeholder={t('selectTimezone')} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="date_range" label={t('date')}>
                                        <DatePicker.RangePicker size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <div id="viewBurnRulesCanvas" className="h-[400px]"></div>
                        <Row gutter={12} className="mt-4">
                            <Col xs={24} md={8} lg={6}>
                                <Button block size="large" onClick={() => navigate(`/loyalty/config/burn-rule`)}>
                                    <BoldButtonLabel labelText={t('back')} />{' '}
                                </Button>
                            </Col>
                        </Row>
                    </section>
                </Card>
            </div>
        </PrimaryLayout>
    );
};


export default BurnRuleView