import { Button, Checkbox, Col, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Typography } from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TextArea from 'antd/es/input/TextArea';
import { validationHelpers } from '../../../../helpers';
import { getFormattedNumeric2DecimalPointValidatorForInput } from '../../../../helpers/validation.helpers';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

interface IProductFormProps {
  // initialFormValues?: any;
  isUpdate: boolean;
  formInstance: FormInstance<any>;
  handleSave: () => void;
  handleGoBack: () => void;
  resetData: () => void;
}

const ProductForm: React.FunctionComponent<IProductFormProps> = ({
  formInstance,
  isUpdate,
  handleSave,
  handleGoBack,
  resetData
}) => {
  const {t} = useTranslation();
  return (
    <Form form={formInstance} layout="vertical" onFinish={handleSave}>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label={t('productIdentifierType')}
            name="product_identifier_type"
            rules={[
              {
                required: true,
                message: t('pleaseEnterProductIdentifierType')
              }
            ]}
          >
            <Select
              placeholder={t('productIdentifierType')}
              size="large"
              allowClear
              options={[
                { label: t('sku'), value: 'SKU' },
                { label: t('gtin'), value: 'GTIN' },
                { label: t('upc'), value: 'UPC' },
                { label: t('ean'), value: 'EAN' },
                { label: t('mpn'), value: 'MPN' }
              ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="item_id"
            label={t('itemId')}
            rules={[
              {
                required: true,
                message: t('pleaseEnterProductItemId')
              },
              {
                max: 30,
                message: t('cannotExceed30Characters')
              }
            ]}
          >
            <Input size="large" placeholder={t('itemId')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <div className="ml-[25px]">
          <Form.Item name="is_variant" label={t('isVariant')}>
              <Radio.Group>
              <Radio value={'TRUE'}>{t('yes')}</Radio>
              <Radio value={'FALSE'}>{t('no')}</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.is_variant !== curValues.is_variant}>
            {({ getFieldValue }) => {
              const isVariant = getFieldValue('is_variant') === 'TRUE';
              return (
                <Form.Item
                  name="article_id"
                  label={t('articleId')}
                  rules={[
                    {
                      required: isVariant,
                      message: t('pleaseEnterProductArticleId')
                    },
                    {
                      max: 30,
                      message: t('cannotExceed30Characters')
                    }
                  ]}
                >
                  <Input size="large" placeholder="Article ID" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="vendor_code"
            label="Vendor Code"
            rules={[
              {
                required: false,
                message: t('pleaseEnterProductVendorCode')
              },
              {
                max: 30,
                message: t('cannotExceed30Characters')
              }
            ]}
          >
            <Input size="large" placeholder={t('vendorCode')}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label={t('supplyTypeCode')}
            name="supply_type_code"
            rules={[
              {
                required: true,
                message: t('pleaseEnterSupplyTypeCode')
              }
            ]}
          >
            <Select
              placeholder={t('supplyTypeCode')}
              size="large"
              allowClear
              options={[
                { label: 'B2B', value: 'B2B' },
                { label: 'B2C', value: 'B2C' }
              ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="product_name"
            label={t('productName')}
            rules={[
              {
                required: true,
                message: t('pleaseEnterProductName')
              },
              {
                max: 100,
                message: t('cannotExceed100Characters')
              }
            ]}
          >
            <Input size="large" placeholder={t('productName')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="product_type"
            label={t('productType')}
            rules={[
              {
                required: false,
                message: t('pleaseEnterProductType')
              },
              {
                max: 50,
                message: t('cannotExceed50Characters')
              }
            ]}
          >
            <Input size="large" placeholder={t('productType')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="department"
            label={t('department')}
            rules={[
              {
                required: true,
                message: t('pleaseEnterDepartment')
              },
              {
                max: 50,
                message: t('cannotExceed50Characters')
              }
            ]}
          >
            <Input size="large" placeholder={t('department')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name="category"
            label={t('category')}
            rules={[
              {
                required: true,
                message: t('pleaseEnterCategory')
              },
              {
                max: 50,
                message: t('cannotExceed50Characters')
              }
            ]}
          >
            <Input size="large" placeholder={t('category')}/>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <div className="ml-[25px]">
            <Form.Item name="branded" label={t('branded')}>
              <Radio.Group>
              <Radio value={'TRUE'}>{t('yes')}</Radio>
              <Radio value={'FALSE'}>{t('no')}</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Col>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.branded !== curValues.branded;
          }}
        >
          {({ getFieldValue, setFieldsValue }) => {
            const branded = getFieldValue('branded');
            if (branded === 'FALSE') {
              return null;
            }
            return (
              <Col xs={12} md={6}>
                <Form.Item
                  name="brand"
                  label={t('brand')}
                  rules={[
                    {
                      required: true,
                      message: t('pleaseEnterBrand')
                    }
                  ]}
                >
                  <Input size="large" placeholder={t('brand')}/>
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>
      </Row>
      <Row gutter={12}>
        <Col xs={24} md={24}>
          <Form.Item
            label={t('description')}
            name="description"
            rules={[
              { required: false, message: t("thisFieldCan'tBeEmpty") },
              { max: 500, message: t('maxLimitIs500Characters') }
            ]}
          >
            <TextArea rows={4} placeholder={t('description')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}></Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name="country_of_origin"
            label={t('countryOfOrigin')}
            rules={[
              {
                required: true,
                message: t('pleaseEnterCountryOfOrigin')
              },
              { max: 100, message: t('maxLimitIs100Characters') }
            ]}
          >
            <Input size="large" placeholder={t('countryOfOrigin')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="lyt_earn"
            label={t('earn')}
            rules={[
              {
                required: false,
                message: t('pleaseEnterEarn')
              },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message:t('enterUpTo10DigitsAnd2Decimals')
              })
            ]}
          >
            <InputNumber className="w-full" size="large" placeholder="Earn" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="lty_redeem"
            label={t('redeem')}
            rules={[
              {
                required: false,
                message: t('pleaseEnterRedeem')
              },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message:t('enterUpTo10DigitsAnd2Decimals')
              })
            ]}
          >
            <InputNumber className="w-full" size="large" placeholder={t('redeem')}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12} className="py-4">
        <Col xs={12} md={6}>
          <Form.Item name="active_status" label={t('active')}>
            <Radio.Group>
            <Radio value={'TRUE'}>{t('yes')}</Radio>
            <Radio value={'FALSE'}>{t('no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Form.Item
            label={t('unitPriceRef')}
            name={'unit_price'}
            rules={[
              { required: false, message:  t("thisFieldCan'tBeEmpty")  },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
               message:t('enterUpTo10DigitsAnd2Decimals')
              })
            ]}
          >
            <InputNumber placeholder="Unit Price" className="w-full" min={0} size="large"></InputNumber>
          </Form.Item>
        </Col>{' '}
        <Col xs={24} md={6}>
          <Form.Item
            label={t('listPriceRef')}
            name={'list_price'}
            rules={[
              { required: false,  message:  t("thisFieldCan'tBeEmpty") },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message:t('enterUpTo10DigitsAnd2Decimals')
              })
            ]}
          >
            <InputNumber placeholder="List Price" className="w-full" min={0} size="large"></InputNumber>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
             label={t('costPriceRef')}
            name={'cost_price'}
            rules={[
              { required: false,  message:  t("thisFieldCan'tBeEmpty")   },
              validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                message:t('enterUpTo10DigitsAnd2Decimals')
              })
            ]}
          >
            <InputNumber placeholder="Cost Price" className="w-full" min={0} size="large"></InputNumber>
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={5} className="text-[#2e2a5b]">
      {t('channelOption')}
      </Typography.Title>
      <Row>
        <div style={{ display: 'flex' }}>
          <Form.Item name="sold_Online" valuePropName="checked">
          <Checkbox>{t('ecom')}</Checkbox>
          </Form.Item>{' '}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Item name="sold_Offline" valuePropName="checked">
          <Checkbox>{t('store')}</Checkbox>
          </Form.Item>
        </div>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Button size="large" block type="primary" htmlType="submit">
          <BoldButtonLabel labelText={isUpdate ? t('update') : t('create')} />
          </Button>
        </Col>{' '}
        {isUpdate && (
          <Col xs={24} md={6}>
            <Button size="large" block onClick={resetData}>
            <BoldButtonLabel labelText={t('reset')} />
            </Button>
          </Col>
        )}
        <Col xs={24} md={6}>
          <Button size="large" block onClick={handleGoBack}>
          <BoldButtonLabel labelText={t('goBack')} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;