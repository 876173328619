import { Button, Card, Col, Form, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import { convertQueryStringToObj, objectHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import i18n from '../../../../i18n';
import { useTranslation } from "react-i18next"

interface IBurnRuleListingProps {}


const BurnRuleListing: React.FC<IBurnRuleListingProps> = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [burnRuleListing, setBurnRuleListing] = React.useState({} as any);
  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, any[]>);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const [searchForm] = Form.useForm();
  const navigate = useNavigate();
  const statusList = [
    { value: 'OPEN', label: t('open') },
    { value: 'ACTIVE', label: t('active') },
    { value: 'EXPIRED', label: t('expired') },
    { value: 'DEFERRED', label: t('deferred') },
    { value: 'ON_HOLD', label: t('onHold') },
    { value: 'CLOSED', label: t('closed') }
  ];
  

  const columns: TableColumnsType<any> = [
    {
      title: t('ruleName'),
      render(value, record, index) {
        return <>{record.name}</>;
      },
      align: 'center'
    },
    {
      title: t('version'),
      render(value, record, index) {
        return <>{record.version}</>;
      },
      align: 'center'
    },
    {
      title: t('rewardName'),
      render(value, record, index) {
        return <>{record.reward_name}</>;
      },
      align: 'center'
    },
    {
      title: t("templateType"),
      render(value, record, index) {
        return <>{record.burn_template_type}</>;
      },
      align: 'center'
    },
    {
      title: t('status'),
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },
    {
      title: t('action'),
      render(value, record, index) {
        return (
          <section className="flex gap-2 justify-center">
            <ActionButton
              action="VIEW"
              title={t("viewRule")}
              onClick={() => navigate(`/loyalty/config/burn-rule/view/${record.id}`)}
            />
            <ActionButton
              onClick={() => navigate(`/loyalty/config/burn-rule/${record.id}/new`)}
              title={t("createNewVersion")}
              action="CREATE_NEW_VERSION"
            />
            <ActionButton
              onClick={() => {
                handleTableExpand(record);
              }}
              title={t("viewAllVersions")}
              action="EXPAND_VERSIONS"
            />
          </section>
        );
      },
      align: 'center'
    },
    {
      title: t('statusUpdate'),
      render(value, record, index) {
        let actions: { label: string; action: string }[] = [];

        if (record.status === 'OPEN') {
          actions.push({ label:t("activate"), action: 'ACTIVE' });
        }
        if (record.status === 'ACTIVE') {
          actions.push(
            ...[
              {
                label: t("onHold"),
                action: 'ON_HOLD'
              },

              {
                label:t("defer"),
                action: 'DEFFERED'
              }
            ]
          );
        }

        if (record.status === 'ON_HOLD') {
          actions.push(
            ...[
              { label:  t("activate"), action: 'ACTIVE' },
              {
                label:t("defer"),
                action: 'DEFFERED'
              }
            ]
          );
        }
        return (
          <section className="flex justify-center">
            {actions.map(({ label, action }) => (
              <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                {label}
              </Button>
            ))}
          </section>
        );
      },
      align: 'center'
    }
  ];

  const handleTableExpand = async (record: any) => {
    if (expandedRowKeys.includes(record.id)) {
      return setExpandedRowKeys([]);
    }

    if (_.isEmpty(expandedRowData[record.id])) {
      await fetchEarnRuleVersions(record.id);
    }
    setExpandedRowKeys([record.id]);
  };

  const fetchEarnRuleVersions = async (id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getBurnRuleVersions(id);
    if (_.isEmpty(errors)) {
      setExpandedRowData(rowData => ({ ...rowData, [id]: data }));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleStatusChange = async (status: string, id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.changeBurnRuleStatus(id, status);
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({ message:t('ruleUpdateSuccess') });
      // const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
      loadInitialData();
      // await Promise.allSettled([handleFilterSearch(offset)]);
    }
    setLoading(false);
  };

  const loadInitialData = async () => {
    await handleFilterSearch(pageControl?.currentPage - 1);
  };

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = { ...formValues, offset, limit: pageControl?.pageSize };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await loyaltyService.getRuleBurnList({
      limit: pageControl?.pageSize,
      offset,
      ...formValues
    });

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      setBurnRuleListing(data);
    }
    setLoading(false);
  };

  const handleClickCreate = () => {
    navigate(`/loyalty/config/burn-rule/create`);
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<any> = [
      {
        title: t('ruleName'),
        render(value, record, index) {
          return <>{record.name}</>;
        },
        align: 'center'
      },
      {
        title: t('version'),
        render(value, record, index) {
          return <>{record.version}</>;
        },
        align: 'center'
      },
      {
        title: t('rewardName'),
        render(value, record, index) {
          return <>{record.reward_name}</>;
        },
        align: 'center'
      },
      {
        title: t('status'),
        render(value, record, index) {
          return <>{record.status}</>;
        },
        align: 'center'
      },
      {
        title: t('startDate'),
        render(value, record, index) {
          let date = record?.start_date ? dayjs(record?.start_date).format('YYYY/MM/DD') : '';
          return <>{date}</>;
        },
        align: 'center'
      },
      {
        title: t('endDate'),
        render(value, record, index) {
          let date = record?.end_date ? dayjs(record?.end_date).format('YYYY/MM/DD') : '';
          return <>{date}</>;
        },
        align: 'center'
      },
      {
        title: t('action'),
        render(value, { id }, index) {
          return (
            <section className="flex gap-2 justify-center">
              <ActionButton
                action="VIEW"
                onClick={() => navigate(`/loyalty/config/burn-rule/view/${id}`)}
                title={t("viewRule")}
              />
            </section>
          );
        },
        align: 'center'
      },

      {
        title: t('statusUpdate'),

        render(value, record, index) {
          let actions: { label: string; action: string }[] = [];

          if (record.status === 'OPEN') {
            actions.push({ label: t("activate"), action: 'ACTIVE' });
          }
          if (record.status === 'ACTIVE') {
            actions.push(
              ...[
                {
                  label: t("onHold"),
                  action: 'ON_HOLD'
                },

                {
                  label: t("defer"),
                  action: 'DEFFERED'
                }
              ]
            );
          }

          if (record.status === 'ON_HOLD') {
            actions.push(
              ...[
                { label:t("activate"), action: 'ACTIVE' },
                {
                  label: t("defer"),
                  action: 'DEFFERED'
                }
              ]
            );
          }
          return (
            <section className="flex justify-center">
              {actions.map(({ label, action }) => (
                <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                  {label}
                </Button>
              ))}
            </section>
          );
        },
        align: 'center'
      }
    ];

    return (
      <>
        <Typography.Title level={5}>{("versions")}</Typography.Title>
        <Table bordered dataSource={expandedRowData[expandedRowKeys[0]]} pagination={false} columns={columns} />
      </>
    );
  };

  const handleOnClear = () => {
    searchForm.resetFields();
    handleFilterSearch(0);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
              {t("burnRules")}
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={handleClickCreate}>
                <BoldButtonLabel labelText={t("create")}></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form
              layout="vertical"
              form={searchForm}
              onFinish={() =>
                pageControl?.currentPage == 1
                  ? handleFilterSearch(0)
                  : setPageControl(prev => ({ ...prev, currentPage: 1 }))
              }
            >
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="burn_template_type" label={t("templateType")}>
                    <Select
                      allowClear
                      placeholder={t("selectTemplateType")}
                      size="large"
                      options={[
                        { label:t("customerOrder"), value: 'CUSTOMER_ORDER' },
                        { label: t("giftCard"), value: 'GIFT_CARD' },
                        { label:t("transferReward"), value: 'TRANSFER_REWARD' }
                      ]}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          burn_template_type: null
                        });
                        handleFilterSearch(0);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="status" label={t("status")}>
                    <Select
                      allowClear
                      placeholder={t("status")}
                      size="large"
                      options={statusList}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          status: null
                        });
                        handleFilterSearch(0);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="pb-4" gutter={12}>
                <Col xs={12} md={3}>
                  <Button type="primary" htmlType="submit" size="large" block>
                    <BoldButtonLabel labelText={t("search")}></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button size="large" onClick={handleOnClear} block>
                    <BoldButtonLabel labelText={t("clear")} />
                  </Button>
                </Col>
              </Row>

              <Table
                loading={false}
                expandable={{
                  expandedRowRender,
                  expandedRowKeys,
                  showExpandColumn: false
                }}
                pagination={{
                  current: pageControl?.currentPage,
                  total: burnRuleListing.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  onChange: (currentPage, pageSize) => setPageControl({ ...pageControl, currentPage, pageSize })
                }}
                bordered
                dataSource={burnRuleListing.data}
                columns={columns}
                rowKey="id"
                scroll={{ x: 1000 }}
              />
            </Form>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default BurnRuleListing;