import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select, Spin, Typography } from 'antd';
import * as React from 'react';
import { countryList, phoneCountryCodeList } from '../../../../data/intl-data';
import { stateList } from '../../../../data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { validationHelpers } from '../../../../helpers';
import { useChannel } from '../../../promotion-engine/hooks/drop-down-hooks/use-channel';
import { useProducts } from '../../../promotion-engine/hooks/drop-down-hooks/use-products';
import { DeleteOutlined } from '@ant-design/icons';
import { useReward } from '../../../promotion-engine/hooks/drop-down-hooks/use-rewards';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import _ from '../../../../helpers/lodash';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

interface ICustomerFormProps {
  initialFormValues?: any;
  isUpdate?: boolean;
  formInstance: FormInstance<any>;
  handleFinish?: () => void;
  handleGoBack?: () => void;
}

const CustomerForm: React.FunctionComponent<ICustomerFormProps> = ({
  initialFormValues,
  formInstance,
  handleFinish,
  isUpdate,
  handleGoBack
}) => {
  const [allChannelOptions, setAllChannelOptions] = React.useState([] as any);
  const { debouncedFetchChannelDropdownOptions, channelDropdownFetching, channelDropDownOptions } = useChannel();
  const { debouncedFetchRewarsDropdownOptions, rewardDropDownFetch, rewardDropDownOptions } = useReward();
  const { t } = useTranslation();

  React.useEffect(() => {
    getChannelList();
  }, []);

  const getChannelList = async () => {
    const { data, errors } = await loyaltyService.getAllChannelList();
    if (_.isEmpty(errors)) {
      let optionData = data?.data?.map((item: any) => {
        let nameStr = `${item?.channel_name} type - ${item?.channel_type}`;
        let obj = {
          label: nameStr,
          value: nameStr,
          channel_type: item?.channel_type
        };
        return obj;
      });
      setAllChannelOptions(optionData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  return (
    <Form form={formInstance} onFinish={handleFinish} layout="vertical" scrollToFirstError={true}>
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          {t('basicDetails')}
        </Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_id"
              label={t('customerId')}
              rules={[
                {
                  required: true,
                  message: t('pleaseEnterCustomerID')
                },
                {
                  max: 20,
                  message:t('max20Characters')
                }
              ]}
            >
              <Input disabled={isUpdate} size="large" placeholder={t('customerId')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="first_name"
              label={t('firstName')}
              rules={[
                {
                  required: true,
                  message: t('pleaseEnterYourFirstName')
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: t('firstNameCanOnlyContainLettersAndSpaces')
                },
                {
                  min: 2,
                  message: t('firstNameMustBeAtLeast2CharactersLong')
                },
                {
                  max: 20,
                 message:t('max20Characters')
                }
              ]}
            >
              <Input size="large" placeholder={t('firstName')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="last_name"
              label={t('lastName')}
              rules={[
                {
                  required: true,
                  message: t('pleaseEnterYourLastName')
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: t('lastNameCanOnlyContainLettersAndSpaces')
                },
                {
                  max: 20,
                  message:t('max20Characters')
                }
              ]}
            >
              <Input size="large" placeholder={t('lastName')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="email"
              label={t('email')}
              rules={[
                {
                  required: true,
                  message: t('pleaseEnterYourEmail')
                },
                {
                  type: 'email',
                  message: t('invalidEmailFormat')
                },
                {
                  max: 50,
                  message:t('max50Characters')
                }
              ]}
            >
              <Input size="large" placeholder={t('email')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('phoneNumber')}
              name={['phone_number']}
              rules={[
                {
                  required: false,
                  message: t('phoneNumberIsRequired')
                },
                {
                  pattern: /^[0-9\s\-\+\(\)]+$/,
                  message: t('phoneNumberMustBeAValidFormat')
                },
                {
                  min: 8,
                  message: t('minShouldBe8Number')
                },
                {
                  max: 15,
                 message:t('max15Characters')
                }
              ]}
            >
              <Input
                addonBefore={
                  <Form.Item
                    initialValue={phoneCountryCodeList[0]?.value}
                    noStyle
                    name={['dial_code']}
                    rules={[
                      { required: true, message: t('dialCodeIsRequired') },
                      {
                        max: 15,
                        message:t('max15Characters')
                      }
                    ]}
                  >
                    <Select style={{ width: 120 }} options={phoneCountryCodeList} size="large" />
                  </Form.Item>
                }
                placeholder={t('enterPhoneNumber')}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="date_of_birth" label={t('dateOfBirth')}>
              <DatePicker size="large" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="sign_up_date" label={t('signUpDate')}>
              <DatePicker size="large" className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_type"
              label={t('customerType')}
              rules={[{ required: true, message: t('customerTypeIsRequired') }]}
            >
              <Select size="large" placeholder={t('selectCustomerType')}>
                <Select.Option value="CUS">{t('customer')}</Select.Option>
                <Select.Option value="EMP">{t('employee')}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="refered_by" label={t('referredBy')}>
              <Input size="large" placeholder={t('enterReferredBy')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="supply_type_code"
              label={t('supplyType')}
              rules={[{ required: true, message: t('thisFieldIsRequired') }]}
            >
              <Select size="large" placeholder={t('selectSupplyType')}>
                <Select.Option value="B2C">B2C</Select.Option>
                <Select.Option value="B2B">B2B</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="country"
              label={t('country')}
              rules={[
                {
                  required: true,
                  message: t('thisFieldIsRequired')
                }
              ]}
            >
              <Select
                options={countryList}
                placeholder={t('selectCountry')}
                allowClear
                size="large"
                onChange={() => {
                  formInstance.setFieldsValue({ state: null });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => {
                return prev.country !== curr.country;
              }}
            >
              {form => {
                const { country } = form.getFieldsValue();
                const stateOptions = stateList[country] || [];

                return (
                  <Form.Item
                    name="state"
                    label={t('state')}
                    rules={[
                      {
                        required: true,
                        message: t('thisFieldIsRequired')
                      }
                    ]}
                  >
                    <Select size="large" placeholder={t('selectState')} options={stateOptions} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="location"
              label={t('location')}
              rules={[
                {
                  max: 50,
                  message:  t('cannotExceed50Characters')
                }
              ]}
            >
              <Input size="large" placeholder={t('enterLocation')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="postal_code"
              label={t('postalCode')}
              rules={[
                {
                  required: false,
                  message:  t('thisFieldIsRequired')
                }
              ]}
            >
              <Input size="large" placeholder={t('enterPostalCode')} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="member_type" label={t('sourceMemberType')}>
              <Input size="large" placeholder={t('enterSourceMemberType')} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      {!isUpdate && (
        <div
          style={{
            height: 'fit-content',
            background: '#9b9b9b29',
            padding: '20px',
            marginBottom: '1rem',
            borderRadius: '10px'
          }}
        >
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            {t('registeredChannelInfo')}
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                name="registered_channel_name"
                label={t('registeredChannel')}
                rules={[{ required: true, message: t('thisFieldIsRequired') }]}
              >
                <Select
                  options={allChannelOptions}
                  placeholder={t('registeredChannelName')}
                  allowClear
                  size="large"
                  onChange={(value:any, objData:any) => {
                    formInstance.setFieldsValue({
                      registered_channel_type: objData?.channel_type,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item name="registered_channel_type" label={t('registeredChannelType')}>
                <Input size="large" placeholder={t('registeredChannelType')} disabled />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name="site_id"
                label={t('siteId')}
                rules={[{ required: false, message: t('thisFieldIsRequired') }]}
              >
                <Input size="large" placeholder={t('siteId')} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          {t('alternateCustomerIdentity')}
        </Typography.Title>
        <Form.List name="channel_identity_info">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row gutter={12} key={key}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={[name, 'channel_name']}
                      label={`${t('channel')} ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: t('thisFieldIsRequired')
                        }
                      ]}
                    >
                      <Select
                        options={allChannelOptions}
                        placeholder={t('channelName')}
                        allowClear
                        size="large"
                        onChange={(value: any, objData: any) => {
                          formInstance.setFields([
                            {
                              name: ['channel_identity_info', name, 'channel_type'],
                              value: objData?.channel_type
                            }
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item name={[name, 'channel_type']} label={t('channelType')}>
                      <Input size="large" placeholder={t('channelType')} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={[name, 'alternate_id']}
                      label={`${t('alternateId')} ${index + 1}`}
                      rules={[
                        { required: true, message: t('thisFieldIsRequired') },
                        { max: 50, message: t('max50Characters') }
                      ]}
                    >
                      <Input size="large" placeholder={`${t('alternateId')} ${index + 1}`}/>
                    </Form.Item>
                  </Col>
                  <Col className="ml-4 mt-8">
                    <DeleteOutlined
                      className="text-red-500 text-xl"
                      title={`${t('deleteChannel')} ${index + 1}`}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button
                  type="primary"
                  onClick={async () => {
                    try {
                      const fieldsToValidate: any[] = [];

                      // Populate fieldsToValidate with paths for channel_identity_info fields
                      fields.forEach((_, index) => {
                        fieldsToValidate.push(['channel_identity_info', index, 'channel_name']);
                        fieldsToValidate.push(['channel_identity_info', index, 'alternate_id']);
                      });

                      // Validate only the specific fields in channel_identity_info
                      await formInstance.validateFields(fieldsToValidate);

                      // If validation passes, add a new field
                      add();
                    } catch (error) {
                      console.log('Validation failed:', error);
                    }
                  }}
                >
                   {t('add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      {!isUpdate && (
        <div
          style={{
            height: 'fit-content',
            background: '#9b9b9b29',
            padding: '20px',
            marginBottom: '1rem',
            borderRadius: '10px'
          }}
        >
          <Typography.Title level={4} className="text-[#2e2a5b] m-0">
            {t('loyaltyReward')}
          </Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item label={t('rewardName')} name="reward_name">
                <Select
                  showSearch
                  allowClear
                  size="large"
                  filterOption={false}
                  placeholder={t('rewardName')}
                  notFoundContent={rewardDropDownFetch ? <Spin size="small" /> : null}
                  onSearch={async (searchTerm) => {
                    if (searchTerm) await debouncedFetchRewarsDropdownOptions(searchTerm);
                  }}
                  options={rewardDropDownOptions}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('rewardPoints')}
                name="reward_points"
                rules={[
                  { required: false, message: t("thisFieldIsRequired") },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder={t('rewardPoints')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('activeInDays')}
                name="active_in_days"
                rules={[
                  { required: false, message: t("thisFieldIsRequired") },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder={t('activeInDays')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('expireInDays')}
                name="expire_in_days"
                rules={[
                  { required: false, message: t("thisFieldIsRequired") },
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <InputNumber min={0} className="w-full" size="large" placeholder={t('expireInDays')} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Button size="large" block onClick={handleGoBack}>
            <BoldButtonLabel labelText={t('goBack')} />
          </Button>
        </Col>
        {typeof handleFinish === 'function' && (
          <Col xs={24} md={6}>
            <Button size="large" block type="primary" htmlType="submit">
              <BoldButtonLabel labelText={isUpdate ? t('update') : t('create')} />
            </Button>
          </Col>
        )}
      </Row>


    </Form>
  );
};

export default CustomerForm;
