import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Spin, Typography } from 'antd';
import DmnModeler from 'dmn-js/lib/Modeler';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { useTimezone } from '../../../../hooks/useTimezone';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { useReward } from '../../../promotion-engine/hooks/drop-down-hooks/use-rewards';
import { createBurnRulesInitialXml } from '../../data';
import { loyaltyService } from '../../services/loyalty.service';
import i18n from '../../../../i18n';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

interface ICreateBurnRulesProps {}

const CreateBurnRules: React.FunctionComponent<ICreateBurnRulesProps> = props => {
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { timezoneSelectOptions } = useTimezone();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const modellerRef = React.useRef<any>(null);
  const { t } = useTranslation();

  const { debouncedFetchRewarsDropdownOptions, rewardDropDownFetch, rewardDropDownOptions } = useReward();

  React.useEffect(() => {
    if (modellerRef.current === null) {
      initializeModeller();
    }
    return () => {
      if (modellerRef.current !== null) {
        modellerRef.current.detach();
        modellerRef.current = null;
      }
    };
  }, []);

  const initializeModeller = async () => {
    const modeller = new DmnModeler({
      container: '#createEarnRulesCanvas'
    });
    modellerRef.current = modeller;

    try {
      const { warnings } = await modeller.importXML(createBurnRulesInitialXml);

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  const handleCreate = async () => {
    setLoading(true);

    const { name, burn_template_type, reward_name, date_range, timezone } = createForm.getFieldsValue();
    const { xml } = await modellerRef.current.saveXML();
    const request: any = {};
    if (date_range?.length) {
      const [start_date, end_date] = date_range;
      request.start_date = start_date.format();
      request.end_date = end_date.format();
    }

    const body = {
      burn_template_type: burn_template_type,
      name: name,
      xml: xml,
      reward_name: reward_name,
      start_date: request.start_date,
      end_date: request.end_date,
      time_zone: timezone
    };
    const { errors } = await loyaltyService.createBurnRule(body);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Rule created successfully' });
      navigate('/loyalty/config/burn-rule');
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleApplicabilityChange = async () => {
    setLoading(true);
    //API CALL

    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('createBurnRule')}
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
          </div>
          <section className="mt-4">
            <Form layout="vertical" onFinish={handleCreate} form={createForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="burn_template_type"
                    label={t('templateType')}
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                  >
                    <Select
                      placeholder={t('selectTemplateType')}
                      size="large"
                      // onChange={() => searchForm.setFieldValue('applicablity_type', null)}
                      options={[
                        { label: t('customerOrder'), value: 'CUSTOMER_ORDER' },
                        { label: t('giftCard'), value: 'GIFT_CARD' },
                        { label: t('transferReward'), value: 'TRANSFER_REWARD' }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[
                      { message: t('thisFieldIsRequired'), required: true },
                      {
                        validator: async (_, ruleName: string) => {
                          if (/\s/.test(ruleName)) {
                            return Promise.reject(new Error(t('noSpacesAllowedInRuleName')));
                          }
                        }
                      }
                    ]}
                    name="name"
                    label={t('ruleName')}
                  >
                    <Input placeholder={t('enterRuleName')} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                    name="reward_name"
                    label={t('rewardName')}
                  >
                    <Select
                      // mode="multiple"
                      showSearch
                      allowClear
                      size="large"
                      filterOption={false}
                      placeholder={t('rewardName')}
                      notFoundContent={rewardDropDownFetch ? <Spin size="small" /> : null}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchRewarsDropdownOptions(searchTerm);
                      }}
                      options={rewardDropDownOptions}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                    name="timezone"
                    label={t('timezone')}
                  >
                    <Select options={timezoneSelectOptions} placeholder={t('selectTimezone')} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label={t('date')}>
                    <DatePicker.RangePicker size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <div id="createEarnRulesCanvas" className="h-[400px]"></div>
              <Row gutter={12}>
                <Col xs={24} md={8} lg={6}>
                  <Button block htmlType="submit" size="large" type="primary">
                    <BoldButtonLabel labelText={t('create')} />{' '}
                  </Button>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <Button block size="large" onClick={() => navigate(`/loyalty/config/burn-rule`)}>
                    <BoldButtonLabel labelText={t('back')} />{' '}
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreateBurnRules;
