import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, Modal, Row, Table, TableColumnsType, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../../../stores/use-loader';
import { convertQueryStringToObj } from '../../../../helpers';
import FieldDisplay from '../../../../components/FieldDisplay';
import { IRewardDetailItem, IRewardDetailResponse, IRewardIdDetail } from '../../types/reward-history';
import CustomPagination from '../../../../components/custom-pagination';
import _ from '../../../../helpers/lodash';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import { formatDateTime } from '../../../../helpers/date.helpers';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

interface IRewardHistoryDetailProps { }

const RewardHistoryDetail: React.FunctionComponent<IRewardHistoryDetailProps> = props => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const { t } = useTranslation();

  const [rewardDetail, setRewardDetail] = React.useState({} as IRewardDetailResponse);
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { customer_id, reward_name, trigger_type, applicability_type, status, channel, site, date_from, date_to } =
    queryStringObj;

  React.useEffect(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');
    loadInitialData(offset, limit);
  }, [searchParams]);

  const loadInitialData = async (offset = 0, limit = 10) => {
    setLoading(true);
    const params = {
      'reward-name': reward_name,
      trigger_type,
      applicability_type,
      status,
      channel,
      site,
      date_from,
      date_to,
      offset,
      limit
    };
    const { data, errors } = await loyaltyService.getRewardHistoryDetails(customer_id, params);

    if (_.isEmpty(errors)) {
      setRewardDetail(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<IRewardDetailItem> = [
    {
      title: t('rewardValue'),
      render(value, record, index) {
        return <>{record.reward_value}</>;
      },
      align: 'center'
    },
    {
      title: t('redeemableValue'),
      render(value, record, index) {
        return <>{record.redeemable_value}</>;
      },
      align: 'center'
    },

    {
      title: t('channel'),
      render(value, record, index) {
        return <>{record.channel}</>;
      },
      align: 'center'
    },

    {
      title: t('site'),
      render(value, record, index) {
        return <>{record.site_id}</>;
      },
      align: 'center'
    },

    {
      title: t('status'),
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },

    {
      title: t('rewardDate'),
      render(value, record, index) {
        return <>{formatDateTime(record.created_date_time)}</>;
      },
      align: 'center'
    },

    {
      title: t('loyaltyType'),
      render(value, record, index) {
        return <>{record.loyality_type}</>;
      },
      align: 'center'
    },
    {
      title: t('applicabilityType'),
      render(value, record, index) {
        return <>{record.applicability_type}</>;
      },
      align: 'center'
    },

    {
      title: t('transactionType'),
      render(value, record, index) {
        return <>{record.transaction_type}</>;
      },
      align: 'center'
    },

    {
      title: <></>,
      render(value, record, index) {
        return (
          <ActionButton
            action="VIEW"
            onClick={() => {
              handleClickRewardDetail(record.id);
            }}
            title={t('rewardDetails')}
          />
        );
      },
      align: 'center'
    }
  ];

  const handleClickRewardDetail = async (rewardId: string) => {
    setLoading(true);

    if (!rewardIdDetailMap[rewardId]) {
      const { data, errors } = await loyaltyService.getRewardIdDetails(rewardId);
      setRewardIdMap(rewardIdMap => ({ ...rewardIdMap, [rewardId]: data }));
      if (!_.isEmpty(errors)) {
        displayErrorNotifications(errors);
        setLoading(false);
        return;
      }
    }

    setSelectedId(rewardId);
    setLoading(false);
  };
  const handlePageChange = (currentPage: number) => {
    loadInitialData(currentPage - 1);
  };

  const [selectedId, setSelectedId] = React.useState<string>('');
  const [rewardIdDetailMap, setRewardIdMap] = React.useState<Record<string, IRewardIdDetail>>({});

  const isModalVisible = !_.isEmpty(rewardIdDetailMap[selectedId]);
  const rewardIdDetail = rewardIdDetailMap[selectedId];
  const navigate = useNavigate();
  const handleBack = () => {
    if (searchParams.get('backUri')) {
      navigate(searchParams.get('backUri') as string);
    } else {
      navigate('/loyalty/reports');
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('customerRewardHistoryDetail')}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="flex gap-8 my-4 flex-wrap">
            <FieldDisplay label={t('customer')} value={customer_id} />
            <FieldDisplay label={t('rewardName')} value={reward_name} />
          </section>
          <section className="mt-8">
            <Table
              pagination={{
                total: rewardDetail?.page_info?.total_pages
                  ? rewardDetail.page_info?.total_pages * parseInt(searchParams.get('limit') as string) || 10
                  : 0,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                pageSize: parseInt(searchParams.get('limit') as string) || 10,
                current: parseInt(searchParams.get('offset') as string) + 1 || 1,
                onChange: (currentPage, pageSize) => {
                  setSearchParams(prev => {
                    prev.set('offset', currentPage - 1 + '');
                    prev.set('limit', pageSize + '');
                    return prev;
                  });
                }
              }}
              bordered
              dataSource={rewardDetail.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>{' '}
          <Button size="large" className="mt-4" type="primary" onClick={handleBack}>
            <BoldButtonLabel labelText={t('goBack')} />
          </Button>
        </Card>
      </div>
      <Modal
        open={isModalVisible}
        footer={null}
        width={700}
        centered
        onCancel={() => {
          setSelectedId('');
        }}
      >
        <Typography.Title level={4}>{t('rewardDetails')}</Typography.Title>
        <section className="flex flex-col flex-wrap gap-4">
          <FieldDisplay label={t('customerId')} value={rewardIdDetail?.customer_id} />
          <FieldDisplay label={t('loyaltyType')} value={rewardIdDetail?.loyality_type} />
          <FieldDisplay label={t('applicabilityType')} value={rewardIdDetail?.applicability_type} />
          <FieldDisplay label={t('rewardName')} value={rewardIdDetail?.reward_name} />
          <FieldDisplay label={t('rewardType')} value={rewardIdDetail?.reward_type} />
          <FieldDisplay label={t('rewardValue')} value={rewardIdDetail?.reward_value} />
          <FieldDisplay label={t('maxUse')} value={rewardIdDetail?.max_use} />
          <FieldDisplay label={t('channel')} value={rewardIdDetail?.channel} />
          <FieldDisplay label={t('pointConversionFactor')} value={rewardIdDetail?.point_conversion_factor} />
          <FieldDisplay label={t('redeemableValue')} value={rewardIdDetail?.redeemable_value} />
          <FieldDisplay label={t('status')} value={rewardIdDetail?.status} />
          <FieldDisplay label={t('transactionType')} value={rewardIdDetail?.transaction_type} />
        </section>
      </Modal>
    </PrimaryLayout>
  );
};

export default RewardHistoryDetail;
