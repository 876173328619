import { Col, Form, Row, Typography } from 'antd';
import Card from 'antd/es/card/Card';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TenantInfo from '../../../../components/TenantIdInfo';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import ProductForm from '../../components/ProductForm';
import { loyaltyService } from '../../services/loyalty.service';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

interface CreateProd {}
const CreateProduct: React.FunctionComponent<CreateProd> = props => {
  const [createProductForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {t} = useTranslation();

  React.useEffect(() => {
    createProductForm.setFieldsValue({
      branded: 'FALSE',
      active_status: 'FALSE'
    });
  }, []);

  const handleOnCreateProduct = async () => {
    const {
      active_status,
      article_id,
      brand,
      branded,
      category,
      country_of_origin,
      department,
      description,
      item_id,
      lyt_earn,
      model,
      product_identifier_type,
      product_name,
      product_type,
      sold_Online,
      supply_type_code,
      vendor_code,
      sold_Offline,
      cost_price,
      list_price,
      unit_price,
      lty_redeem,
      is_variant
    } = createProductForm.getFieldsValue();
    const bodyData = {
      product_identifier_type: product_identifier_type,
      item_id: item_id,
      article_id: article_id,
      vendor_code: vendor_code,
      supply_type_code: supply_type_code,
      product_name: product_name,
      product_type: product_type,
      department: department,
      category,
      description: description,
      model: model,
      brand: brand || '',
      country_of_origin: country_of_origin,
      is_variant: is_variant === 'TRUE' ? true : false,
      is_branded: branded === 'TRUE' ? true : false,
      sold_online: sold_Online,
      sold_offline: sold_Offline,
      lty_earn: lyt_earn ? Number(lyt_earn) : 0,
      lty_redeem: lty_redeem ? Number(lty_redeem) : 0,
      unit_price: unit_price ? Number(unit_price) : 0,
      cost_price: cost_price ? Number(cost_price) : 0,
      list_price: list_price ? Number(list_price) : 0,
      is_active: active_status === 'TRUE' ? true : false
    };

    const { errors } = await loyaltyService.createProduct(bodyData);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: 'Product created successfully!' });
      const backUri = searchParams.get('goBackTo');
      if (backUri) {
        navigate(`${backUri}`);
      } else {
        navigate(`/loyalty/customers`);
      }
    }
  };

  const handleOnGoBack = () => {
    const backUri = searchParams.get('goBackTo');
    if (backUri) {
      navigate(`${backUri}`);
    } else {
      navigate(`/loyalty/customers`);
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {("addProduct")}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <ProductForm
              isUpdate={false}
              formInstance={createProductForm}
              handleSave={handleOnCreateProduct}
              handleGoBack={handleOnGoBack}
              resetData={() => {}}
            />
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreateProduct;
