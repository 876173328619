
import { t } from "i18next";
export const createRuleTriggerTypeData: Record<string, any> = {
  EVENT: {
    label: t('event'),
    value: 'EVENT',
    applicabilityTypes: [
      { label: t('order'), value: 'Order' },
      { label: t('referral'), value: 'Referral' },
      { label: t('survey'), value: 'Survey' },
      { label: t('review'), value: 'Review' },
      { label: t('signUp'), value: 'Sign up' }
    ]
  },
  MANUAL: {
    label: t('manual'),
    value: 'MANUAL',
    applicabilityTypes: [
      { label: t('sale'), value: 'Sale' },
      { label: t('abandonCart'), value: 'Abandon Cart' },
      { label: t('inactiveBuyer'), value: 'Inactive Buyer' }
    ]
  },
  SCHEDULED: {
    label: t('scheduled'),
    value: 'SCHEDULED',
    applicabilityTypes: [{ label: t('scheduled'), value: 'Scheduled' }]
  }
};

export const triggerTypeSelectOptions = Object.keys(createRuleTriggerTypeData).map(key => {
  const { label, value } = createRuleTriggerTypeData[key];

  return { label, value };
});

export const createEarnRulesInitialXml =
  '<?xml version="1.0" encoding="UTF-8"?>\r\n<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="definitions_1s6twx8" name="definitions" namespace="http://camunda.org/schema/1.0/dmn" exporter="dmn-js (https://demo.bpmn.io/dmn)" exporterVersion="14.1.1">\r\n  <decision id="decision_11g4w17" name="Earn Rules">\r\n    <decisionTable id="decisionTable_0uc9rao">\r\n      <input id="input1" label="Customer Tier">\r\n        <inputExpression id="inputExpression1" typeRef="string">\r\n          <text>customer.tier_type</text>\r\n        </inputExpression>\r\n      </input>\r\n    <output id="OutputClause_17wjx0u" label="Reward Name" name="reward_name" typeRef="string" />\r\n      <output id="OutputClause_1wdb6iy" label="Reward Value" name="value" typeRef="string" />\r\n      <output id="OutputClause_1ei9xkf" label="Expiry in days" name="expiry_in_days" typeRef="string" />\r\n    </decisionTable>\r\n  </decision>\r\n</definitions>';

export const viewRewardHistoryData: Record<string, any> = {
  'earnAndBurn': {
    label: t('earnAndBurn'),
    value: 'earnAndBurn',
    applicabilityTypes: [
      { label: t('order'), value: 'Order' },
      { label: t('referral'), value: 'Referral' },
      { label: t('survey'), value: 'Survey' },
      { label: t('review'), value: 'Review' },
      { label: t('signUp'), value: 'Sign up' },
      { label: t('promo'), value: 'Promo' }
    ]
  },
  'actionOriented': {
    label: t('actionOriented'),
    value: 'actionOriented',
    applicabilityTypes: [
      { label: t('sale'), value: 'Sale' },
      { label: t('abandonCart'), value: 'Abandon Cart' },
      { label: t('inactiveBuyer'), value: 'Inactive Buyer' },
      { label: t('scheduled'), value: 'Scheduled' }
    ]
  }
};

export const triggerTypeSelectOptionForRewardHistory = Object.keys(viewRewardHistoryData).map(key => {
  const { label, value } = viewRewardHistoryData[key];

  return { label, value };
});

export const createBurnRulesInitialXml =
  '<?xml version="1.0" encoding="UTF-8"?>\r\n  <definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="definitions_1s6twx8" name="definitions" namespace="http://camunda.org/schema/1.0/dmn" exporter="dmn-js (https://demo.bpmn.io/dmn)" exporterVersion="14.1.1">\r\n  <decision id="Decision_16jrlcr" name="Decision 1">\r\n    <decisionTable id="DecisionTable_1dzc28c">\r\n      <input id="Input_1" label="Customer tier">\r\n        <inputExpression id="InputExpression_1" typeRef="string">\r\n          <text>burn_payload.customer.member_type</text>\r\n        </inputExpression>\r\n      </input>\r\n      <input id="InputClause_0mbogy3" label="Total Available Rewards">\r\n  <inputExpression id="LiteralExpression_1yoj3xu" typeRef="number">\r\n <text>active_reward_value</text>\r\n </inputExpression>\r\n </input>\r\n <input id="InputClause_1lixl8i" label="Item Burn Point Available">\r\n <inputExpression id="LiteralExpression_0bx4679" typeRef="string">\r\n <text>some item in burn_payload.line_items satisfies item.lty_burn&gt;0</text>\r\n </inputExpression>\r\n </input>\r\n  <output id="Output_1" label="Max Redeemption" name="max_redemption" typeRef="string" />\r\n  <rule id="DecisionRule_1jf6eq5">\r\n <inputEntry id="UnaryTests_06t700q">\r\n <text></text>\r\n </inputEntry>\r\n <inputEntry id="UnaryTests_0xgflgh">\r\n <text></text>\r\n </inputEntry>\r\n <inputEntry id="UnaryTests_1ar2lji">\r\n <text></text>\r\n </inputEntry>\r\n <outputEntry id="LiteralExpression_1s6c32a">\r\n <text></text>\r\n </outputEntry>\r\n <outputEntry id="LiteralExpression_1ioxkix">\r\n <text></text>\r\n </outputEntry>\r\n </rule>\r\n    </decisionTable>\r\n  </decision>\r\n</definitions>';
