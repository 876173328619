import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Table, TableColumnType, TableColumnsType, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import { IEarnRuleDetails, IRuleDescription, IRuleReward } from '../../types/earn-rules';
import { useNavigate, useParams } from 'react-router-dom';
import { loyaltyService } from '../../services/loyalty.service';
import { useLoader } from '../../../../stores/use-loader';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import _ from '../../../../helpers/lodash';
import { validateJson } from '../../../../helpers/json.helpers';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { formatDateTime } from '../../../../helpers/date.helpers';

interface IEarnRulesTestProps {}

const serialize = JSON.stringify;
const deserialize = JSON.parse;

const EarnRulesTest: React.FunctionComponent<IEarnRulesTestProps> = props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [earnRuleDetails, setEarnRuleDetails] = React.useState({} as IEarnRuleDetails);
  const [ruleDescription, setRuleDescription] = React.useState({} as IRuleDescription);
  const [rewardObject, setRewardObject] = React.useState([] as IRuleReward[]);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    fetchRuleDetails();
  }, []);

  const fetchRuleDescription = async ({
    trigger_type,
    applicability_type
  }: {
    trigger_type: string;
    applicability_type: string;
  }) => {
    setLoading(true);

    const { errors, data } = await loyaltyService.getRuleDescriptions({
      applicabilityType: applicability_type || 'Sign up',
      triggerType: trigger_type
    });

    if (_.isEmpty(errors)) {
      setRuleDescription(data);
      try {
        jsonForm.setFieldValue('input_json', serialize(deserialize(data.input_json), null, 2));
      } catch (err) {
        console.log(err);
      }
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const fetchRuleDetails = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getEarnRuleDetail(id as string);
    if (_.isEmpty(errors)) {
      setEarnRuleDetails(data);
      await fetchRuleDescription(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const isValid = validateJson(event.currentTarget.value, deserialize);
    isValid &&
      event.currentTarget.value.trim() !== '' &&
      jsonForm.setFieldValue('input_json', serialize(deserialize(event.currentTarget.value), null, 2));
  };

  const [jsonForm] = Form.useForm();

  const testRule = async () => {
    setLoading(true);
    const ruleJson = deserialize(jsonForm.getFieldsValue().input_json);
    const { data, errors } = await loyaltyService.testEarnRule(id as string, ruleJson);
    if (_.isEmpty(errors)) {
      setRewardObject(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<IRuleReward> = [
    {
      title: 'Applicability Type',
      align: 'center',
      render(_, record) {
        return <>{record.applicability_type}</>;
      }
    },
    {
      title: 'Loyalty Type',
      align: 'center',
      render(_, record) {
        return <>{record.loyality_type}</>;
      }
    },
    {
      title: 'Reward Type',
      align: 'center',
      render(_, record) {
        return <>{record.reward_type}</>;
      }
    },
    {
      title: 'Transacation Type',
      align: 'center',
      render(_, record) {
        return <>{record.transaction_type}</>;
      }
    },
    {
      title: 'Reward Value',
      align: 'center',
      render(_, record) {
        return <>{record.reward_value}</>;
      }
    },
    {
      title: 'Reward Name',
      align: 'center',
      render(_, record) {
        return <>{record.reward_name}</>;
      }
    },
    {
      title: 'Expire By',
      align: 'center',
      render(_, record) {
        return <>{formatDateTime(record.expire_by)}</>;
      }
    },
    {
      title: 'Active By',
      align: 'center',
      render(_, record) {
        return <>{formatDateTime(record.active_by)}</>;
      }
    },
    {
      title: 'Max Use',
      align: 'center',
      render(_, record) {
        return <>{record.max_use}</>;
      }
    },
    {
      title: 'Point Conversion Factor',
      align: 'center',
      render(_, record) {
        return <>{record.point_conversion_factor}</>;
      }
    },
    {
      title: 'Loyalty Number',
      align: 'center',
      render(_, record) {
        return <>{record.loyalty_number}</>;
      }
    }
  ];

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Test Rule - {earnRuleDetails.name}
              </Typography.Title>
            </Col>
          </Row>
          <section className="flex gap-4 flex-wrap">
            <TenantInfo />
            <Typography.Text className="text-[#898c98]">
              Trigger Type - <span className="font-bold text-black">{earnRuleDetails.trigger_type}</span>{' '}
            </Typography.Text>{' '}
            <Typography.Text className="text-[#898c98]">
              Expire Applicability Type -{' '}
              <span className="font-bold text-black">{earnRuleDetails.applicability_type}</span>{' '}
            </Typography.Text>
          </section>

          <section className="my-4">
            <Form form={jsonForm} onFinish={testRule} layout="vertical">
              <Row>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Input JSON"
                    name="input_json"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true },
                      () => ({
                        validator(_, value) {
                          if (!value || validateJson(value, deserialize)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Invalid JSON'));
                        }
                      })
                    ]}
                  >
                    <Input.TextArea onBlur={handleBlur} rows={25} placeholder="Enter JSON" />
                  </Form.Item>
                </Col>
              </Row>
              {!_.isEmpty(rewardObject) && (
                <section className="my-2">
                  <Typography.Title level={4}>Rewards</Typography.Title>
                  <Table dataSource={rewardObject} bordered pagination={false} columns={columns} />
                </section>
              )}
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Button htmlType="submit" type="primary" block>
                    <BoldButtonLabel labelText="Test Rule"></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <Button block onClick={() => navigate(`/loyalty/config/earn-rule`)}>
                    <BoldButtonLabel labelText={'Back'} />{' '}
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EarnRulesTest;
