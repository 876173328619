import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuProps,
  Row,
  Select,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import { convertQueryStringToObj, fileHelpers, objectHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { triggerTypeSelectOptionForRewardHistory, viewRewardHistoryData } from '../../data';
import { loyaltyService } from '../../services/loyalty.service';
import { IRewardData, IRewardHistoryResponse } from '../../types/reward-history';
import i18n from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import { AnalyticsService } from '../../services/Analytics.service';
import { DownloadOutlined } from '@ant-design/icons';

interface IRewardHistoryProps {}

const RewardHistory: React.FunctionComponent<IRewardHistoryProps> = props => {
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const [rewardHistory, setRewardHistory] = React.useState({} as IRewardHistoryResponse);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listParams, setListParams] = React.useState<any>({});
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  React.useEffect(() => {
    if (pageControl?.currentPage && pageControl?.pageSize) loadInitialData();
  }, [pageControl]);

  React.useEffect(() => {
    if (!_.isEmpty(queryStringObj)) {
      let formParams: any = { ...queryStringObj };
      if (queryStringObj.reward_from) {
        const date_range = [dayjs(queryStringObj.reward_from), dayjs(queryStringObj.reward_to)];
        formParams = { ...formParams, date_range };
      }
      form.setFieldsValue(formParams);
    }
  }, []);

  const loadInitialData = async () => {
    await handleSearch(pageControl?.currentPage - 1);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    let params = { ...formValues, offset, limit: pageControl?.pageSize };
    if (formValues.date_range) {
      let reward_from = formValues.date_range[0].format('YYYY-MM-DD');
      let reward_to = formValues.date_range[1].format('YYYY-MM-DD');
      delete params.date_range;
      params = { ...params, reward_from, reward_to };
    }
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getRewardHistory(filteredParams);

    if (_.isEmpty(errors)) {
      setRewardHistory(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<IRewardData> = [
    {
      title: t('customer'),
      render(value, record, index) {
        return <>{record.customer_id}</>;
      },
      align: 'center'
    },
    {
      title: t('rewardName'),
      render(value, record, index) {
        return <>{record.reward_name}</>;
      },
      align: 'center'
    },
    {
      title: t('rewardType'),
      render(value, record, index) {
        return <>{record.reward_type}</>;
      },
      align: 'center'
    },
    {
      title:  t('active'),
      render(value, record, index) {
        let data = record?.active?.toFixed(2);
        return <>{data || '0.00'}</>;
      },
      align: 'center'
    },
    {
      title:  t('redeemed'),
      render(value, record, index) {
        let data = record?.redeemed?.toFixed(2);
        return <>{data || 0.0}</>;
      },
      align: 'center'
    },
    {
      title: t('pending'),
      render(value, record, index) {
        return <>{record.pending || 0}</>;
      },
      align: 'center'
    },
    {
      title:t('expired'),
      render(value, record, index) {
        return <>{record.expired || 0}</>;
      },
      align: 'center'
    },
    {
      title: <></>,
      render(value, record, index) {
        return (
          <ActionButton
            action="VIEW"
            title={t('viewDetails')}
            onClick={() => {
              handleClickDetail(record.customer_id, record.reward_name);
            }}
          ></ActionButton>
        );
      },
      align: 'center'
    }
  ];

  const handleClickDetail = (customer_id: string, reward_name: string) => {
    const backUri = window.location.pathname + `?${searchParams.toString()}`;
    const params = { ...queryStringObj, offset: undefined, customer_id, reward_name, backUri };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const paramString = new URLSearchParams(filteredParams).toString();

    navigate(`/loyalty/reports/customer/detail?${paramString}`);
  };

  const handlePageChange = (currentPage: number) => {
    handleSearch(currentPage - 1);
  };

  const getReportData = async () => {
    setLoading(true);
    const { data, errors } = await AnalyticsService.getRewardHistoryReport();
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'Reward History Report', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleMenuClick = async (e: any) => {
    setLoading(true);
    if (e.key === '1') {
      const queryString = searchParams.toString();
      const queryStringObj = convertQueryStringToObj(queryString);
      console.log('queryStringObj', queryStringObj);
      const { data, errors } = await AnalyticsService.getRewardHistoryReport(queryStringObj);
      if (_.isEmpty(errors)) {
        fileHelpers.triggerFileDownload({ data, fileName: 'Reward History Report', extension: '.xlsx' });
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const menuItems: MenuProps['items'] = [
    {
      label: t("downloadCurrentPage"),
      key: '1',
      icon: <DownloadOutlined />
    }
  ];

  const menuProps: MenuProps = {
    items: menuItems,
    onClick: handleMenuClick
  };

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
              {t('rewardHistory')}
              </Typography.Title>
            </Col>
            {/* <Col>
              <Button type="primary" onClick={getReportData}>
                <BoldButtonLabel labelText="Download" />
              </Button>
            </Col> */}
            <Col xs={24} md={6} lg={4}>
              <Dropdown.Button menu={menuProps} type="primary" onClick={getReportData}>
               {t("downloadAll")}
              </Dropdown.Button>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form
              layout="vertical"
              onFinish={() =>
                pageControl?.currentPage == 1 ? handleSearch(0) : setPageControl(prev => ({ ...prev, currentPage: 1 }))
              }
              form={form}
            >
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="loyality_type" label={t('loyaltyType')}>
                    <Select
                      allowClear
                      placeholder={t('selectLoyaltyType')}
                      size="large"
                      onChange={() => form.setFieldValue('applicablity_type', null)}
                      options={triggerTypeSelectOptionForRewardHistory}
                      onClear={() => {
                        form.setFieldsValue({
                          loyality_type: null
                        });
                        handleSearch(0);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.loyality_type !== curValues.loyality_type;
                    }}
                  >
                    {form => {
                      const { loyality_type } = form.getFieldsValue();
                      const options = viewRewardHistoryData[loyality_type]?.applicabilityTypes || [];

                      return (
                        <Form.Item name="applicablity_type" label={t('applicabilityType')}>
                          <Select
                            allowClear
                            placeholder={t('selectApplicabilityType')}
                            notFoundContent={<>{t("selectALoyaltyTypeToViewOptions")}</>}
                            size="large"
                            options={options}
                            onClear={() => {
                              form.setFieldsValue({
                                applicablity_type: null
                              });
                              handleSearch(0);
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>{' '}
                <Col xs={24} md={6}>
                  <Form.Item name="status" label={t('status')}>
                    <Select
                      allowClear
                      placeholder={t('selectStatus')}
                      size="large"
                      options={[
                        { label: t('initiated'), value: 'INITIATED' },
                        { label: t('active'), value: 'ACTIVE' },
                        { label: t('expired'), value: 'EXPIRED' },
                        { label: t('redeemed'), value: 'REDEEMED' }
                      ]}
                      onClear={() => {
                        form.setFieldsValue({
                          status: null
                        });
                        handleSearch(0);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="customerId" label={t('customerId')}>
                    <Input
                      placeholder={t('enterCustomerId')}
                      size="large"
                      onBlur={(e: any) => {
                        if (e.target.value === '') {
                          handleSearch(0);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="channel" label={t('channel')}>
                    <Select
                      allowClear
                      placeholder={t('selectChannel')}
                      size="large"
                      options={[{ label:  t('online'), value: 'ONLINE' }]}
                      onClear={() => {
                        form.setFieldsValue({
                          channel: null
                        });
                        handleSearch(0);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label={t('rewardDate')}>
                    <DatePicker.RangePicker size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button type="primary" block size="large" htmlType="submit">
                    <BoldButtonLabel labelText={t('search')} />
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button
                    size="large"
                    onClick={() => {
                      form.resetFields();
                      handleSearch(0);
                    }}
                    block
                  >
                    <BoldButtonLabel labelText="Clear" />
                  </Button>
                </Col>
              </Row>
            </Form>{' '}
            <section className="mt-4">
              <Table
                pagination={{
                  current: pageControl?.currentPage,
                  total: rewardHistory?.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],

                  onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize })
                }}
                bordered
                dataSource={rewardHistory.data}
                columns={columns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default RewardHistory;
