import { Button, Card, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

interface IChannelModalProps {
  formInstance: FormInstance<any>;
  handleOnSave: () => void;
  handleOnModalClose?: () => void;
  modalHead: string;
}
const ChannelModal: React.FunctionComponent<IChannelModalProps> = ({
  formInstance,
  modalHead,
  handleOnSave,
  handleOnModalClose
}) => {
  const {t}  = useTranslation();
  return (
    <Form form={formInstance} layout="vertical" onFinish={handleOnSave}>
      <Card>
        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              name={'channel_name'}
              label={t('enterChannelName')}
              rules={[
                { required: true, message: t("thisFieldCantBeEmpty") },
                { max: 50, message:t('maxLimitIs50Characters') },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message:  t('onlyLettersNumbersHyphensUnderscoresAllowed')
                }
              ]}
            >
              <Input placeholder={t('enterChannelName')} size="large" allowClear />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              name={'channel_type'}
              label={t('selectChannel')}
              rules={[
                { required: true, message: t("thisFieldCantBeEmpty")}
                // { max: 100, message: 'Max limit is 100 characters' }
              ]}
            >
              <Select
                allowClear
                size="large"
                placeholder="Select Channel"
                options={[
                  { label: t('ecom'), value: 'ECOM' },
                  { label: t('store'), value: 'STORE' },
                  { label:t('marketplace'), value: 'MARKETPLACE' }
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={24}>
            <Form.Item
              label={t('description')}
              name="channel_desc"
              rules={[
                // { required: true, message: "This field can't be empty" },
                { max: 100, message:  t('maxLimitIs100Characters') }
              ]}
            >
              <TextArea rows={4} placeholder={t('description')} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Button htmlType="submit" block type="primary" size="large" className="mb-4">
              <BoldButtonLabel labelText={t(modalHead)} />
            </Button>
          </Col>
          <Col xs={12} md={8}>
            <Button onClick={handleOnModalClose} block size="large" className="mb-4">
              <BoldButtonLabel labelText={t('cancel')} />
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ChannelModal;
