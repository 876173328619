import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, Form, Row, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import CustomerForm from '../../components/CustomerForm';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { countryCodeMap, countryStateMap } from '../../../../data';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

interface ICreateCustomerProps {}

const CreateCustomer: React.FunctionComponent<ICreateCustomerProps> = props => {
  const [createForm] = Form.useForm();
  const {t}= useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  // React.useEffect(() => {
  //   createForm.setFieldsValue({
  //     channel_identity_info: [{}]
  //   });
  // }, []);

  const handleCreateCustomer = async () => {
    setLoading(true);
    const {
      customer_id,
      first_name,
      last_name,
      email,
      dial_code,
      phone_number,
      channel1,
      channel2,
      channel3,
      alternate_id1,
      alternate_id2,
      alternate_id3,
      date_of_birth,
      sign_up_date,
      customer_type,
      refered_by,
      supply_type_code,
      channel,
      postal_code,
      location,
      state,
      country,
      member_type,
      reward_name,
      reward_points,
      active_in_days,
      expire_in_days,
      channel_identity_info,
      registered_channel_name,
      registered_channel_type,
      site_id
    } = createForm.getFieldsValue();
    let ChannelInfo = channel_identity_info?.map((item: any) => {
      let cnName = item?.channel_name?.split('type')[0].trim();
      let obj = {
        ...item,
        channel_name: cnName
      };
      return obj;
    });

    const request = {
      customer_id: customer_id.trim(),
      first_name,
      last_name,
      email,
      dial_code,
      phone_number: phone_number ? phone_number : null,
      channel_identity_info: ChannelInfo ? ChannelInfo : [],
      date_of_birth: date_of_birth ? date_of_birth.format('YYYY-MM-DD') : undefined, // YYYY-MM-DD
      sign_up_date: sign_up_date ? sign_up_date.format('YYYY-MM-DD') : undefined, // YYYY-MM-DD
      customer_type,
      refered_by,
      supply_type_code,
      postal_code,
      location,
      state,
      country,
      state_code: state && country ? countryStateMap[country][state]?.state_code : undefined,
      country_code: countryCodeMap[country],
      member_type,
      reward_name,
      value: reward_points,
      active_in_days,
      expire_in_days
    };

    let headers = {
      channel: registered_channel_name.split('type')[0].trim(),
      site: site_id
    };

    const { errors } = await loyaltyService.createCustomer(request, headers);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: 'Customer created successfully!' });
      handleGoBack();
    }
    setLoading(false);
  };
  const handleGoBack = () => {
    const backUri = searchParams.get('goBackTo');
    if (backUri) {
      navigate(`${backUri}`);
    } else {
      navigate(`/loyalty/customers`);
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
              {t("createCustomer")}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <CustomerForm formInstance={createForm} handleFinish={handleCreateCustomer} handleGoBack={handleGoBack} />
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreateCustomer;
